import i18n from './i18n.js';

export class ZatTables {

	constructor(table) {
		this._initialised = false;
		this.table = table || $('table.zat-table').eq(0);
		this._selected = [];
		this.selected = [];
		this.columns = [];
		this._options = {}; // Defaults are set in datatables.js using $.extend($.fn.dataTable.defaults, { ... });
		this.lookups = null;
		this.text = {
			selected : i18n.__('datatables.selected'),
			info : i18n.__('datatables.info'),
			deselect : ` (<a href="#deselect" class="deselect" title="${i18n.__('datatables.deselect')}">${i18n.__('datatables.clear')}</a>)`
		};
		this.parsed = {
			selected : '',
			info : ''
		};
		this.selectable = false;
		this.dom = false;

		// Used by ZatPark to check whether this module is loaded
		window['ZatTables'] = true;
	}

	init() {
		var columnDefs = [];
		var listmenu = this.table.closest('div.data-table').find('span.table-menu');
		var self = this;
		var colReorder = { fixedColumnsLeft: 0, fixedColumnsRight : 0 };
		var columns = this.table.find('thead th').length;
		var tid = this.table.attr('id');
		var data = this.table.data();
		var td0 = this.table.find('tbody tr').eq(0).find('td');
		var dom = td0.length > 0; // this.table.data('ajax') == undefined;
		self.dom = dom;
		var attrMap = { 'sort' : 'sort', 'order': 'sort',  'filter' : 'filter', 'search': 'filter', 'type' : 'type' };

		this.columns = [];

		this.table
			.on('stateSaveParams.dt', function(e, settings, data) {
				// Do nothing for now
			})
			.on('stateLoadParams.dt', function(e, settings, data) {
				// Record default column visibility
				self.storeColumnDefaults(settings);

				// Clear any filtering before applying state
				data.search.search = '';
				$.each(data.columns, function(k, column) {
					column.search.search = '';
				});
			})
			.on('error.dt', function (e, settings, techNote, message) {
				console.group('Datatables error');
				console.error(message);
				console.info(`http://datatables.net/tn/${techNote}`);
				console.log(settings);
				console.groupEnd();
			})
			.on('preInit.dt', function (e, settings, json) {
				// Record default column visibility only if stateLoadParams has not run
				self.storeColumnDefaults(settings);

				let $throbber = $(this).closest('div.data-table').find('[data-spinner="zt-throbber"]');
				$('div.dataTables_processing').append($throbber).removeClass('callout');
				$throbber.fadeIn(400);
			})
			.on('init.dt', function (e, settings, json) {
				// Add div containers to the table ("Displaying 1 to 10 of a possible 100. 0 rows selected. Show 10")
				let tid = $(this).attr('id');
				let $info = $(`<small class="zt-info text-dark-gray margin-horizontal-1" id="${tid}_info"></small>`)
					.html(self.parsed.info);
				let $dropdown = $(`<label><small class="text-dark-gray margin-horizontal-1">${i18n.__('datatables.show')} `
					+ `<select class="text-dark-gray margin-0 inline-select zat-table" data-target="${tid}">`
					+ $.map(settings.aLengthMenu, function(v) { return `<option value="${v}">${v}</option>`; }).join('')
					+ '</select></small></label>');
				let $selected = $(`<small class="zt-selected text-dark-gray margin-horizontal-1" id="${tid}_selected"></small>`)
					.html(self.parsed.selected);
				let $bl = $(this).parent('div.dataTables_wrapper').find('.bl');
				if (settings.oFeatures.bLengthChange) {
					$bl.prepend($dropdown);
				}
				if (settings.oFeatures.bInfo) {
					$bl.prepend($info);
				}
				let $br = $(this).parent('div.dataTables_wrapper').find('.br');
				if (self.selectable) {
					$br.append($selected);
					$(this).trigger('select.zt');
				}
				$br.append($(`#${tid}_footer`));

				// Set the page length dropdown value
				if (settings.oSavedState && settings.oSavedState.length) {
					$(this).parent('div.dataTables_wrapper').find('select.zat-table').val(settings.oSavedState.length);
				}

				// Create list of columns in settings dropdown
				var ul = $('<ul />').attr('id', `${tid}_ulvis`).addClass('menu vertical');
				var api = $(this).DataTable();
				$.each(self.columns, function(k, column) {
					if (typeof column == 'undefined') {
						return true;
					}
					column.index = k;
					column.actual = api.colReorder.transpose(k);
				});
				self.columns.sort(function(a, b) {
					if (a.actual == b.actual) {
						return 0;
					}
					return a.actual < b.actual ? -1 : 1;
				});
				$.each(self.columns, function(k, column) {
					if (typeof column == 'undefined') {
						return true;
					}
					let input = $('<input type="checkbox" />')
						.attr('id', `${tid}_colvis_` + column.index)
						.attr('name', column.field)
						.prop('checked', api.column(column.actual).visible())
						.click(function() {
							let api = self.table.DataTable();
							api.column(api.colReorder.transpose(column.index)).visible($(this).is(':checked'));
							api.columns.adjust().draw();
						});
					let label = $('<label>')
						.attr('for', `${tid}_colvis_` + column.index)
						.text(column.title);
					ul.append($('<li>').attr('id', `${tid}_livis_` + column.index).append(input).append(label));
				});
				$('#' + tid + '_visibility').append(ul);
				$(this).trigger('init.zt', [settings, json, self.columns.filter(c => typeof c == 'object')]);
			})
			.on('length.dt', function(e, settings, len) {
				$(this).parent('div.dataTables_wrapper').find('select.zat-table').val(len);
			})
			.on('draw.dt', function(e) {
				let tid = $(this).attr('id');
				let api = $(this).DataTable();
				let page = api.page.info();
				let id;

				page.begin = page.end == 0 ? 0 : page.start + 1;
				self.parsed.info = jQuery.fn.dataTable.render._untokenise(self.text.info, page);
				// Set the content of the page info element
				$(this).parent('div.dataTables_wrapper').find('.zt-info').html(self.parsed.info);
				// Ensure that the length dropdown is correct
				$(this).parent('div.dataTables_wrapper').find('select.zat-table').val(page.length);
				// If we have an actions column loop through and recreate dropdowns
				self.buildMenus();

				api.responsive.rebuild();
				api.responsive.recalc();
			})
			.on('preXhr.dt', function (e, settings, data) {
				let $parent = $(this).closest('div.data-table');
				$parent.find('div.zt-footer').hide();
				$parent.find('div.dataTables_paginate').hide();

				// Pass search.smart config value to server
				if (typeof data.search == 'object') {
					data.search.smart = 'true';
					if (
						typeof settings.oInit == 'object'
						&& typeof settings.oInit.search == 'object'
					) {
						data.search = $.extend({}, data.search, settings.oInit.search);
					}
				}

				// Loop through columns
				$.each(data.columns, function (k, column) {

					// Set search.type property for columns with an associated filter using the data-filter attribute
					if ((column.data == null) || (column.search.value.length == 0)) {
						return true; // continue
					}
					// Is there an element with the attribute data-filter="<field>" and a data-filter-type attribute?
					let $filter = $('*[data-filter="' + column.data + '"]');
					// If so, set search.type from the value of the data-filter-type attribute
					if ($filter.length && $filter.data('filter-type')) {
						data.columns[k].search.type = $filter.data('filter-type');
					}
				});
			})
			.on('xhr.dt', function(e, settings, json, xhr) {
				let $parent = $(this).closest('div.data-table');
				$parent.find('div.zt-footer').show();
				$parent.find('div.data-table-footer').find('div.dataTables_paginate').show();

				// If lookups have been sent in our json update our local array
				if (json && json.lookups) {
					// The following doesn't work so well with more than one table on a page
					// // jQuery.fn.dataTable.render._lookups = json.lookups;
					// This could still be problematic but is better
					jQuery.fn.dataTable.render._lookups = Object.assign(
						jQuery.fn.dataTable.render._lookups ? jQuery.fn.dataTable.render._lookups : {},
						json.lookups
					);
				}
			})
			.on('column-visibility.dt', function (e, settings, column, state) {
				// Do nothing
			})
			.on('column-visibility.zt', function(e, column, state) {
				// Do nothing
			})
			.on('column-reorder.dt', function (e, settings, details) {
				var changed = false;
				var api = $(this).DataTable();
				$.each(self.columns, function(k, column) {
					if (typeof column == 'undefined') {
						return true;
					}
					if (column.actual == details.from) {
						changed = true;
					}
					column.actual = api.colReorder.transpose(column.index);
				});
				if (false === changed) {
					return;
				}
				self.columns.sort(function(a, b) {
					if (a.actual == b.actual) {
						return 0;
					}
					return a.actual < b.actual ? -1 : 1;
				});
				$.each(self.columns, function(k, column) {
					if (typeof column == 'undefined') {
						return true;
					}
					$(`#${tid}_ulvis`).append($(`#${tid}_livis_` + column.index).detach());
				});

				// If we have an actions column loop through and recreate dropdowns
				self.buildMenus();
			})
			.on('buttons-processing.dt', function (e, indicator) {
				if (indicator) {
					//console.log('button processing start');
				}
				else {
					//console.log('button processing stop');
				}
			})
			.on('responsive-display.dt', function (e, datatable, row, showHide, update) {
				// Reinitialise any dropdowns
				$(row.node()).next().find('.dropdown-pane').each(function(k, v) {
					new Foundation.Dropdown($(this));
				});
			})
			.on('select.zt', function(e, total = 0, data = null) {
				// Store the data in an accessible place
				$(this).data('selected', data ? data : []);
				// Set the content of the row selection info element
				self.parsed.selected = jQuery.fn.dataTable.render._untokenise(self.text.selected, { total: total, plural: total == 1 ? '' : 's' });
				if (total > 0) {
					self.parsed.selected = self.parsed.selected + self.text.deselect;
				}
				if ($(this).parent('div.dataTables_wrapper').length) {
					$(this).parent('div.dataTables_wrapper').find('.zt-selected').html(self.parsed.selected);
				}
				// Enable/disable bulk action buttons depending on selection status
				if (total == 0) {
					$('[data-requires-selection]').attr('disabled', 'disabled').prop('disabled', true);
					$('[data-requires-selection]').addClass('disabled');
					$('[data-requires-multiple-selection]').addClass('disabled');
				} else {
					$('[data-requires-selection]').removeAttr('disabled').prop('disabled', false);
					$('[data-requires-selection]').removeClass('disabled');
				}
				// Enable bulk action buttons depending on selection being greater than 1
				if (total > 1) {
					$('[data-requires-multiple-selection]').removeAttr('disabled').prop('disabled', false);
					$('[data-requires-multiple-selection]').removeClass('disabled');
				} else {
					$('[data-requires-multiple-selection]').attr('disabled', 'disabled').prop('disabled', true);
					$('[data-requires-multiple-selection]').addClass('disabled');
				}
			})
			.on('deselect.zt', function(e) {
				self.selected = [];
				self._selected = [];
				$(this).trigger('select.zt', [self.selected.length, self.selected]);
			});

		if (this.selectable) {
			this.table.trigger('select.zt');
		}

		this.table.find('thead th').each(function(c, col) {

			let data = $(this).data('data') || null;
			let title = $(this).text();

			// Build an array of fields with user-friendly names to use in filters etc.
			if (false === $(this).is('.never')) {
				self.columns[c] = {
					field: data,
					title: title
				}
			}

			// Set content for actions columns
			if (typeof $(this).data('actions') != 'undefined') {

				// Create a columnDef for this column
				columnDefs.push({
					"title": title,
					"targets": $(this).index(),
					"className": 'noselect text-right',
					"data": null,
					"defaultContent": listmenu.html(),
					"orderable" : false,
					"searchable" : false,
					"responsivePriority" : 1,
				});

				colReorder.fixedColumnsRight++;

				// Remove column from colvis columns
				self.hideColumn($(this));
			}

			// Setup responsive control from <th data-responsive>
			if (typeof $(this).data('responsive') != 'undefined') {
				columnDefs.push({
					"targets": $(this).index(),
					"className": 'control noselect',
					"defaultContent": '',
					"data": null,
					"orderable" : false,
					"searchable" : false,
				});

				colReorder.fixedColumnsRight++;

				self._options.responsive = { "details" : { "type" : 'column', "target" : $(this).index() } }

				// Remove column from colvis columns
				self.hideColumn($(this));
			}

			// Set content for selector columns
			if (typeof $(this).data('selector') != 'undefined') {

				// Create a columnDef for this column
				columnDefs.push({
					"targets": $(this).index(),
					"data": null,
					"defaultContent": '<label><span class="sr-only">Select Row</span><input class="selector" type="checkbox" value="1" /></label>',
					"orderable" : false,
					"searchable" : false,
					"responsivePriority" : 1,
				});

				if (c == 0) {
					colReorder.fixedColumnsLeft = 1;
				}

				function getUniqueID() {
					return 'multi-zt-' + Date.now() + '-' + Math.random().toString(36).substring(2, 9); // Generate a unique ID using timestamp and random string
				}

				var uniqueID = getUniqueID();  // Generate a unique ID for this specific table instance

				var multi = $(`<label class="sr-only" for="${uniqueID}">Select All</label><input id="${uniqueID}" type="checkbox" />`)
					.addClass('multi-zt')
					.click(function(e) {
						var active = $(this).is(':checked');
						var checkboxes = self.table.find('input.selector');
						checkboxes = active ? checkboxes.not(':checked') : checkboxes.filter(':checked');
						checkboxes.each(function() {
							$(this).click();
						});
						$(this).prop('checked', active);
					});

				self.table.find('th').eq(c).append(multi);

				self.table
					.on('draw.dt', function() {
						$('.multi-zt').prop('checked', false);
					})
					.on('select.zt', function(total, selected) {
						$('.multi-zt').prop('checked', false);
					})
					.on('select.dt', function(e, api, type, indexes) {
						let serverSide = api.init().bServerSide;
						if (serverSide) {
							$.each(indexes, function(i, row) {
								let rowId = api.row(row).id();
								self._selected[rowId] = 1;
							});
							self.selected = Object.keys(self._selected);
						} else {
							self.selected = Array.from(api.rows({ selected : true }).data().pluck('id'));
						}
						api.rows(indexes).nodes().to$().find('input.selector').prop('checked', true);
						self.table.trigger('select.zt', [self.selected.length, self.selected]);
					})
					.on('deselect.dt', function(e, api, type, indexes) {
						let serverSide = api.init().bServerSide;
						if (serverSide) {
							$.each(indexes, function(i, row) {
								let rowId = api.row(row).id();
								delete self._selected[rowId];
							});
							self.selected = Object.keys(self._selected);
						} else {
							self.selected = Array.from(api.rows({ selected : true }).data().pluck('id'));
						}
						api.rows(indexes).nodes().to$().find('input.selector').prop('checked', false);
						self.table.trigger('select.zt', [self.selected.length, self.selected]);
					});

				// Add callback for row display to set row selection on pagination
				self._options.rowCallback = function(row, data) {
					let $row = $(row);
					let $input = $row.find('input.selector');
					let api = self.table.DataTable();
					let id = api.row($row).id();
					if ($.inArray(id, self.selected) !== -1) {
						api.row($row).select();
					}
				}

				self.selectable = true;

				self._options.select = {
					toggleable: true,
					style: 'multi+shift', // 'api'
					selector: 'td:not(.noselect)',
					className: 'selected',
					info : true, // false
				};

				// Remove column from colvis columns
				self.hideColumn($(this));
			}

			// Set column name to data value if not already set
			if (typeof $(this).attr('data-name') == 'undefined') {
				$(this).attr('data-name', $(this).data('data'));
			}

			// Set the column's render function if one has been specified
			// Use attributes data-render (function name, string) and data-render-params (function parameters, single value or json array)
			if (typeof $(this).attr('data-render') != 'undefined') {
				var renderer;
				var fname = $(this).attr('data-render').toString();
				var params = false;
				if (typeof $(this).attr('data-render-params') != 'undefined') {
					params = $(this).data('render-params');
					if (typeof params != 'object') {
						params = [params];
					}
				}
				if (params !== false) {
					renderer = $.fn.dataTable.render[fname].apply(null, params);
				} else {
					renderer = $.fn.dataTable.render[fname];
				}
				columnDefs.push({
					"targets": $(this).index(),
					"render": renderer
				});
			}

			// If we are parsing the dom for data we need to set columnDefs otherwise our messing^ will mean data-* attributes are ignored.
			// ^By setting columnDefs for some columns (like our selector) all td[data-*] attributes become ignored (which seems a bit silly to me)
			if (dom && data) {
				let $col = td0.eq(c);
				let colDef = { data: { _ : `${data}.display` } };
				$.each(attrMap, function(attr, method) {
					if ($col.data(attr)) {
						colDef.targets = c;
						colDef.data[method] = `${data}.@data-${attr}`;
					}
				});
				if (colDef.targets != undefined) {
					columnDefs.push(colDef);
				}
			}
		});

		this._options.colReorder = colReorder;
		this._options.columnDefs = columnDefs;

		this._options.language = {
			// processing:     "Traitement en cours...",
			// search:         "Rechercher&nbsp;:",
			// lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
			// info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
			// infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
			// infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
			// infoPostFix:    "",
			// loadingRecords: "Chargement en cours...",
			// zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
			// emptyTable:     "Aucune donnée disponible dans le tableau",
			paginate: {
				first:      i18n.__('datatables.first'),
				previous:   i18n.__('datatables.previous'),
				next:       i18n.__('datatables.next'),
				last:       i18n.__('datatables.last'),
			},
			aria: {
				sortAscending:  `: ${i18n.__('datatables.sort.ascending')}`,
				sortDescending: `: ${i18n.__('datatables.sort.descending')}`,
			}
		};

		this._initialised = true;
	}

	options() {
		if (false == this._initialised) {
			this.init();
		}
		return this._options;
	}

	/**
	 * Remove column from colvis columns
	 */
	hideColumn($column) {
		var self = this;
		var index = $column.index();
		delete self.columns[index];
		let selector = ':not(:eq(' + index + '))';
		$.each(self._options.buttons, function(k, button) {
			if (typeof button == 'object' && button.extend == 'colvis') {
				if (typeof button.columns == 'undefined') {
					self._options.buttons[k].columns = selector;
				} else {
					if (typeof self._options.buttons[k].columns == 'string') {
						self._options.buttons[k].columns = [self._options.buttons[k].columns];
					}
					self._options.buttons[k].columns.push(selector);
				}
			} else if (typeof button == 'string' && button == 'colvis') {
				self._options.buttons[k] = { extend: 'colvis', columns: selector };
			}
		});
	}

	/**
	 * Tries to convert a data() row from the dom format to the ajax format
	 */
	parseData(row) {
		let parsed = $.extend({}, row);
		$.each(parsed, function(c, column) {
			if (typeof column == 'object') {
				if (column.display) {
					parsed[c] = column.display;
					if (column['@data-order']) {
						parsed[c] = column['@data-order'];
					}
				}
			}
		});
		return parsed;
	}

	/**
	 * Get the numeric column index for a column name.
	 *
	 * @param 	string 		column 				The column name.
	 * @return 	integer 						The column index.
	 */
	static getColumnIndex(filter, dataTable) {
		dataTable = dataTable || $('table.dataTable').eq(0).DataTable();
		return dataTable.column(filter + ':name').index();
	}

	/**
	 * Ensure that we have a numeric column index.
	 *
	 * @param 	mixed 		column 			The column name or index.
	 * @return 	integer 						The column index.
	 */
	static getNumericIndex(column, dataTable) {
		if (isNaN(column)) {
			return ZatTables.getColumnIndex(column, dataTable);
		}
		return Number(column);
	}

	/**
	 * Perform a search on the table.
	 *
	 * @param 	mixed 		column 			The column name or index.
	 * @param 	search 		string 			The value to search on.
	 * @param 	dataTable 	dataTable 		The datatable to search on.
	 * @return 	void
	 */
	static search(column, search, dataTable) {
		column = ZatTables.getNumericIndex(column, dataTable);
		dataTable = dataTable || $('table.dataTable').eq(0).DataTable();
		dataTable.column(column).search(search).draw();
	}

	/**
	 * State change handler which can be called on 'state.zt' event when a filter is updated from a loaded state.
	 *
	 * @param 	event 		e   			The event object.
	 * @param 	string 		value 			The value to set.
	 * @return 	void
	 */
	static stateUpdate(e, value) {
		$(this).val(value);
		if ($(this).hasClass('sumo-select')) {
			$(this)[0].sumo.reload();
		}
	}

	/**
	 * Shared function to build or rebuild action menus
	 *
	 * @param 	jQuery 		$table 			The table in question.
	 * @param 	object 		api 			The DataTables API.
	 * @return 	void
	 */
	buildMenus() {
		let api = this.table.DataTable();
		let dom = this.dom;
		let tid = this.table.attr('id');
		const self = this;
		this.table.find('td div.actions-menu').each(function() {
			let $row = $(this).closest('tr');
			let rowId = api.row($row).id();
			// The data array differs depending on whether we have a json or dom source
			let row = this.dom ? ZatTables.parseData(api.row($row).data()) : api.row($row).data();
			let id;
			let visible = 0;

			// Generate menu id.
			// Use data-row-uuid on your table to amend (default would be "{id}") e.g.: data-row-uuid="{tariffType}_{id}"
			let uuid = rowId;
			let rowUuid = (self.table.data('rowUuid') !== undefined && self.table.data('rowUuid') !== null) ? self.table.data('rowUuid') : null;

			if (rowUuid) {
				let params = $.extend({}, api.row($row).data(), { context: { table: tid, id: rowId } });
				uuid = jQuery.fn.dataTable.render._untokenise(rowUuid, params);
			}
			id = tid + '_actions_menu_' + uuid;

			// Create menu and dropdown
			$(this)
				.addClass('dropdown-pane')
				.attr('data-dropdown', '')
				.attr('id', id)
				.prev('a.list-menu')
				.attr('data-toggle', id)
				.end()
				.find('li a').each(function() {
					$(this).trigger('menuitem.zt', [row]);
					if ($(this).parent().css('display') != 'none') {
						visible++;
						let $a = $(this);
						$.each(this.attributes, function() {
							if (this.specified) {
								if (this.value.length) {
									$a.attr(this.name, jQuery.fn.dataTable.render._untokenise(this.value, row));
								}
							}
						});
					}
				});

			// If no menu items are visible stop the link from showing the dropdown
			if (visible == 0) {
				$(this).prev('a.list-menu').removeAttr('data-toggle').addClass('disabled');
			}
		});

		// Initialise any dropdowns we've created
		this.table.find('.dropdown-pane').each(function(k, v) {
			new Foundation.Dropdown($(this));
		});
	}

	/**
	 * Records the default visibility of our columns.
	 *
	 * @param  object settings The table setttings.
	 * @return void
	 */
	storeColumnDefaults(settings) {
		if (typeof this.table.data('visibility') == 'undefined') {
			const columns = [];
			$.each(settings.aoColumns, function(k, v) {
				columns[v.idx] = v.bVisible;
			});
			this.table.data('visibility', columns);
		}
	}
}
